export default {
	init: (Choices) => {

        // Custom select
        //////////////////////////////////////////////////
        const select = document.querySelector('.js-select');

        const customSelect = new Choices(select, {
            allowHTML: true,
            removeItemButton: true,
            loadingText: 'Chargement...',
            noResultsText: 'Aucun résultat',
            noChoicesText: 'Aucun résultat',
            itemSelectText: 'Sélectionner',
            placeholderValue: select.dataset.placeholder
        });


	}
}