export default {
	init: (Swiper, Autoplay, Navigation) => {

        // Slider carousel
        ////////////////////////////////////////////////
        const sliderEls = document.querySelectorAll('.js_carousel_slider')

        if (sliderEls.length) {
            sliderEls.forEach(slider => {

                let autoplayOptions = false;

                if (slider.dataset.animationMode === "autoplay") {
                    autoplayOptions = {
                        delay: 5000,
                    }
                }

                const prevControls = slider.closest('.js-carousel').querySelector('.js-carousel-prev')
                const nextControls = slider.closest('.js-carousel').querySelector('.js-carousel-next')
                
                const carouselSwiper = new Swiper(slider, {
                    slidesPerView: 1,
                    spaceBetween: 32,
                    modules: [Autoplay, Navigation],
                    autoplay: autoplayOptions,
                    centerInsufficientSlides: true,
                    breakpoints: {
                        540: {
                            slidesPerView: 2
                        },
                        767: {
                            slidesPerView: 2.4
                        },
                        992: {
                            slidesPerView: 2
                        }
                    },
                    navigation: {
                        nextEl: nextControls,
                        prevEl: prevControls,
                    },
                })

            })
        }

	}
}