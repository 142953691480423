/*
|--------------------------------------------------------------------------------
|                                   GsapManager
|--------------------------------------------------------------------------------
|
| GsapManager is a lightweight library to handle scroll animations using GSAP & Scroll Trigger
|
*/

/*
|
| Dependencies
|---------------
*/
// import gsap from '@gsap/shockingly';
// import ScrollTrigger from '@gsap/shockingly/ScrollTrigger';

import gsap from "../lib/@gsap/shockingly/dist/gsap.js";;
import ScrollTrigger from "../lib/@gsap/shockingly/dist/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

/*
|
| Class
|--------
*/
class GsapManager {

    /*
    |
    | Constructor
    |--------------
    */
    constructor(params = {}){
        this.params = this.getParams(params);
        this.tweens = {
            tweens: {},
            items: {}
        };
    }


    /*
    |
    | getDefaults
    |--------------
    */
    getDefaults(){
        return {
            loadEvent     : [window, 'DOMContentLoaded'],
            scrollTrigger : {
                start         : 'top bottom',
                toggleActions : 'play complete none reverse' //play complete none reset
            },
            tweenParams   : {
                start : '-=0.4',
            },
            selectors     : {
                wrappers : '[data-anim]',
                items    : '[data-anim-item]'
            }
        }
    }


    /*
    |
    | getParams
    |--------------
    */
    getParams(params){
        const defaults = { ...this.getDefaults() };
        const { scrollTrigger, tweenParams, selectors, ...uniterableParams } = params;

        if(params.hasOwnProperty('scrollTrigger')){
            defaults.scrollTrigger = { ...defaults.scrollTrigger, ...scrollTrigger };
        }

        if(params.hasOwnProperty('tweenParams')){
            defaults.tweenParams   = { ...defaults.tweenParams, ...tweenParams };
        }

        return { ...defaults, ...uniterableParams };
    }


    /*
    |
    | Init
    |-------
    */
    init(){
        const wrappers = gsap.utils.toArray(this.params.selectors.wrappers);
        
        wrappers.forEach(wrapper => {
            const trigger = wrapper.dataset.trigger ? wrapper.closest(wrapper.dataset.trigger) : wrapper;
            wrapper.dataset.animOn === "load" ? this.runEntranceAnimations(wrapper) : this.runScrollAnimations(wrapper, trigger)
        });
    }


    /*
    |
    | runEntranceAnimations
    |------------------------
    */
    runEntranceAnimations(wrapper){
        const [ domElement, event ] = this.params.loadEvent;
        const timeline              = this.createTimeline(wrapper, { paused: true });

        domElement.addEventListener(event, () => timeline.play(), false);
    }


    /*
    |
    | runScrollAnimations
    |----------------------
    */
    runScrollAnimations(wrapper, trigger){
        const { scrollTrigger } = this.params;
        const additionalScrollTriggerOptions = { 
            trigger: trigger
        }
        let timelineOptions = {};
        
        timelineOptions.scrollTrigger = { ...scrollTrigger, ...additionalScrollTriggerOptions };
        
        this.createTimeline(wrapper, timelineOptions);
    }


    /*
    |
    | createTimeline
    |-----------------
    */
    createTimeline(wrapper, timelineOptions = {}){
        const { start: startDefault } = this.params.tweenParams;

        if(!wrapper.dataset.anim){
            const timeline             = gsap.timeline(timelineOptions);
            const { items: animItems } = this.params.selectors;
            const items                = wrapper.querySelectorAll(animItems);

            items.forEach((item, index) => {
                const params = this.getTweenParams(item, index, startDefault, timelineOptions, timeline);
                const dataItem = item.dataset.animItem;
                
                this.tweenItemExists(dataItem) && this.tweens.items[dataItem](params);
            });

            return timeline;
        } else {
            const params = this.getTweenParams(wrapper, 0, startDefault, timelineOptions);
            const dataTween = wrapper.dataset.anim;

            this.tweenExists(dataTween) && this.tweens.tweens[dataTween](params);
        }
    }

    getTweenParams(item, index, startDefault, timelineOptions = {}, timeline = null){
        const dataStart = item.dataset.start;
        let start       = index === 0 ? 'start' : startDefault;
        start           = dataStart ? dataStart : start;
        
        let params = {
            item : item,
            gsap : gsap,
            start: start,
            options: timelineOptions
        }

        if(timeline !== null){
            params.timeline = timeline
        }

        return params;
    }

    /*
    |
    | add
    |----------
    */
    add(key, callback){
        this.tweens['tweens'][key] = callback;
    }

    /*
    |
    | addItem
    |----------
    */
    addItem(key, callback){
        this.tweens['items'][key] = callback;
    }

    /*
    |
    | tweenExists
    |------------------
    */
    tweenExists(animItem){
        return this.control(this.tweens.tweens.hasOwnProperty(animItem), this.getMessage('tween_not_exist', animItem));
    }

    /*
    |
    | tweenItemExists
    |------------------
    */
    tweenItemExists(animItem){
        return this.control(this.tweens.items.hasOwnProperty(animItem), this.getMessage('tween_item_not_exist', animItem));
    }


    /**
    |
    | Helper: control
    |------------------
    */
    control(condition, message, selector = null) {
        if (!condition) {
            if (selector === null) {
                console.error(message);
            } else {
                console.error(message, selector);
            }
        }

        return condition;
    }

    
    /*
    |
    |  Helper: getMessage
    |----------------------
    */
    getMessage(key, ...strings){
        const messages = {
            'tween_exist' : `The tween: "${ strings[0] }" has already been defined`,
            'tween_not_exist' : `The tween: "${ strings[0] }" not exist`,
            'tween_item_not_exist' : `The tween item: "${ strings[0] }" not exist`,
        }

        return messages[key];
    }
}

export default GsapManager;