export default {
	init: (utils, gsap, ScrollTrigger) => {

		// Set CSS Variables
        /////////////////////////////////////////////////////////////////////////////////
		const header = document.querySelector('.js-header');

		if (header) {
			utils.setCSSVariables('--header-height', `${header.offsetHeight}px`)
        	window.addEventListener('resize', () => utils.setCSSVariables('--header-height', `${header.offsetHeight}px`))
		}

		// Menu
        /////////////////////////////////////////////////////////////////////////////////
		const submenuLinks = document.querySelectorAll('.js-submenu-link')

		if (submenuLinks.length) {
			submenuLinks.forEach(link => {

				const navItem = link.closest('.js-nav-item');
				const submenuEl = navItem.querySelector('.js-submenu');
				const submenuItems = submenuEl.querySelectorAll('.js-submenu-item');
				const submenuTl = gsap.timeline({ paused: true})

				submenuTl.set(submenuEl, { display: "block" }, 'start')
					     .from(submenuEl, { scaleY: 0, duration: 0.4, ease: "power2.out" }, 'start')
						 .from(submenuItems, {autoAlpha: 0, y:30, duration: 0.3, ease: "power2.out", stagger: 0.08}, '-=0.3')
				
				navItem.addEventListener('mouseover', () => submenuTl.timeScale(1).play())
				navItem.addEventListener('mouseleave', () => submenuTl.timeScale(2).reverse())


			})
		}

		// Menu mobile
        /////////////////////////////////////////////////////////////////////////////////
		const burgerBtnEl = document.querySelector('.js-burger-btn')
		const menuMobileTl = gsap.timeline({ paused: true })

		menuMobileTl.set('.js-mobilenav', { display: "block" }, 'start')
		            .from('.js-mobilenav-layer', { scaleX: 0, duration: 0.6, ease: "power3.out" }, 'start')
					.from('.js-mobilenav-item-text', { opacity: 0, x: -100, duration: 0.6, ease: 'power3.out', stagger: 0.075 }, 'start')
					.from('.js-mobilenav-line', { scaleX:0, duration: 0.6, ease: 'power3.out', stagger:0.075 }, 'start+=0.2')
					.from('.js-mobilenav-icon svg', { x:-20, opacity: 0, duration: 0.6, ease: 'power3.out' }, '-=0.5')
		
		if (burgerBtnEl) {
			
			burgerBtnEl.addEventListener('click', function(e){
				e.preventDefault()

				if (burgerBtnEl.classList.contains('--active')) {
					menuMobileTl.timeScale(2).reverse()
					burgerBtnEl.classList.remove('--active')
					utils.enableScroll();
				} else {
					menuMobileTl.timeScale(1).play()
					burgerBtnEl.classList.add('--active')
					utils.disableScroll();
				}
			})

		}

		//Menu Mobile subnav
		const mobileNavSubLinks = document.querySelectorAll('.js-mobilenav-submenu-link');

		if (mobileNavSubLinks.length) {
			mobileNavSubLinks.forEach(link => {

				const submenu = link.closest('.js-mobilenav-item').querySelector('.js-mobilenav-submenu')
				const submenuItems = submenu.querySelectorAll('.js-mobilenav-item')
				const timeline = gsap.timeline({ paused: true});

				timeline.set(submenu, { display: "block" }, 'start')
				        .from(submenuItems, { opacity: 0, x: -30, duration: 0.6, ease: 'power3.out', stagger: 0.075 }, 'start')
				
				link.addEventListener('click', function(e){
					e.preventDefault()
	
					if (link.classList.contains('--active')) {
						timeline.timeScale(2).reverse()
						link.classList.remove('--active')
					} else {
						timeline.timeScale(1).play()
						link.classList.add('--active')
					}
				})



			})
		}

		// Scrolling header
		/////////////////////////////////////////////////////////////
		/////////////////////////////////////////////////////////////
		const body = document.querySelector('body');
	  
		ScrollTrigger.create({
			markers: false,
			trigger: body,
			start: 'top -5%',
			onUpdate: self => {
				if (self.direction === 1) {
					body.classList.add('--scrolling-down');
					body.classList.remove('--scrolling-up');
				} else if(self.progress === 0) {
					body.classList.remove('--scrolling-up');
				} else {
					body.classList.add('--scrolling-up');
					body.classList.remove('--scrolling-down');
				}
			},
			onLeaveBack: () => {
				body.classList.remove('scrolling-up');
			},
		});


	}
}