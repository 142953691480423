export default {
	init: (Swiper, Pagination, gsap) => {

        // Slider key numbers
        ////////////////////////////////////////////////
        const sliderEls = document.querySelectorAll('.js-keyNumbers_slider')

        if (sliderEls.length) {
            sliderEls.forEach(slider => {
                
                const keyNumbersSwiper = new Swiper(slider, {
                    slidesPerView: 1,
                    modules: [Pagination],
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                        clickable: true
                    },
                })

            })
        }

        // Counter animation
        /////////////////////////////////////////////////
        const counterEls = document.querySelectorAll('.js-counter')

        if (counterEls.length) {
            counterEls.forEach(counterEl => {

                const numberTarget = parseInt(counterEl.textContent);
                const trigger = counterEl.closest('.keyNumbers')

                gsap.fromTo(counterEl, { 
                    innerText: 0,
                }, {
                    innerText: numberTarget, 
                    duration: 2, 
                    delay: 1,
                    snap: {
                        innerText:1
                    },
                    scrollTrigger: {
                        trigger: trigger,
                        start: "top bottom-=20%"
                    }
                });

            })
        }

	}
}