export default {
	init: (Swiper, Pagination) => {

        // Slider news
        ////////////////////////////////////////////////
        const sliderEls = document.querySelectorAll('.js_news_slider')

        if (sliderEls.length) {
            sliderEls.forEach(slider => {
                
                const newsSwiper = new Swiper(slider, {
                    slidesPerView: 1,
                    spaceBetween: 32,
                    modules: [Pagination],
                    centerInsufficientSlides: true,
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                        clickable: true
                    },
                    breakpoints: {
                        540: {
                          slidesPerView: 2
                        },
                        991: {
                            slidesPerView: 3
                        },
                    }
                })

            })
        }

	}
}