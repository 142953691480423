export default {
	init: (Swiper, Pagination) => {

        // Slider articles
        ////////////////////////////////////////////////
        const sliderEls = document.querySelectorAll('.js_articles_slider')

        if (sliderEls.length) {
            sliderEls.forEach(slider => {
                
                const articlesSwiper = new Swiper(slider, {
                    slidesPerView: 1,
                    spaceBetween: 32,
                    modules: [Pagination],
                    centerInsufficientSlides: true,
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                        clickable: true
                    },
                    breakpoints: {
                        540: {
                          slidesPerView: 2
                        },
                        991: {
                            slidesPerView: 3
                        },
                    }
                })

            })
        }

	}
}