export default {
	init: (utils) => {

		//Scroll to top of page
		///////////////////////////////////////////////////////////////
		const scrollTopBtn = document.querySelector('.js-footer-scroll')

		if(scrollTopBtn){
			scrollTopBtn.addEventListener('click', () => window.scrollTo({ top: 0, behavior: 'smooth' }))
		}

	}
}