// Dependencies
///////////////////////////////////////////////
///////////////////////////////////////////////
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import Swiper, { Navigation, Pagination, Autoplay } from "swiper";

// import gsap from "@gsap/shockingly";
// import SplitText from '@gsap/shockingly/SplitText';
// import ScrollTrigger from "@gsap/shockingly/ScrollTrigger";

import gsap from "./lib/@gsap/shockingly/dist/gsap.js";
import SplitText from './lib/@gsap/shockingly/dist/SplitText.js';
import ScrollTrigger from "./lib/@gsap/shockingly/dist/ScrollTrigger.js";

gsap.registerPlugin(SplitText, ScrollTrigger) 

import Choices from "choices.js";
import GsapManager from './components/GsapManager';

// Utils
///////////////////////////////////////////////
///////////////////////////////////////////////
import * as utils from './utils/utils';
import Router from './utils/router';

// Layout
///////////////////////////////////////////////
///////////////////////////////////////////////
import layout from './layout/layout'
import header from './layout/header';
import footer from './layout/footer';


// Pages
///////////////////////////////////////////////
///////////////////////////////////////////////
import list from './pages/list';

// Blocks
///////////////////////////////////////////////
///////////////////////////////////////////////
import b02KeyItems from './blocks/b02_key_items'
import b03KeyNumbers from './blocks/b03_key_numbers'
import b04Testimonials from './blocks/b04_testimonials'
import b06Carousel from './blocks/b06_carousel'
import b09Articles from './blocks/b09_articles'
import b10News from './blocks/b10_news'
import b11BannerAnimated from './blocks/b11_banner_animated'

// Routing
///////////////////////////////////////////////
///////////////////////////////////////////////
const routes = new Router([
    {
        'file': layout,
        'dependencies': [utils, gsap, GsapManager, SplitText]
    },
    {
        'file': header,
        'dependencies': [utils, gsap, ScrollTrigger],
        'resolve': '.js-header'
    },
    {
        'file': footer,
        'dependencies': [utils],
        'resolve': '.footer'
    },
    {
        'file': b11BannerAnimated,
        'dependencies': [utils, gsap],
        'resolve': '.bannerAnimated'
    },
    {
        'file': b02KeyItems,
        'dependencies': [Swiper, Pagination],
        'resolve': '.keyItems'
    },
    {
        'file': b03KeyNumbers,
        'dependencies': [Swiper, Pagination, gsap],
        'resolve': '.keyNumbers'
    },
    {
        'file': b04Testimonials,
        'dependencies': [Swiper, Pagination, Autoplay, Navigation],
        'resolve': '.testimonials'
    },
    {
        'file': b06Carousel,
        'dependencies': [Swiper, Autoplay, Navigation],
        'resolve': '.carousel'
    },
    {
        'file': b09Articles,
        'dependencies': [Swiper, Pagination],
        'resolve': '.js_articles_slider'
    },
    {
        'file': b10News,
        'dependencies': [Swiper, Pagination],
        'resolve': '.js_news_slider'
    },
    {
        'file': list,
        'dependencies': [Choices],
        'resolve': '.js-select'
    }
]);

window.addEventListener('DOMContentLoaded', () => routes.load())