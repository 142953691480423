export default {
	init: (utils, gsap, GsapManager, SplitText) => {

        const body = document.querySelector('body')

        // Loader
        ////////////////////////////////////////////////////////////////////////////////
        const tlLoader = gsap.timeline({ paused: true })
        let loaderAlreadyPlayed = utils.getCookie('loader_played');
        if(document.querySelector('.js-loader-logo') && document.querySelector('.js-loader')){
            tlLoader.to('.js-loader-logo', {y: -40, autoAlpha: 0, duration: 1, ease: 'power2.out'}, 'start')
                    .to('.js-loader', { scaleY: 0, duration: 1, ease: "power2.inOut" }, "+=0")
                    .add(() => {
                        utils.dispachEvent(body, 'dom:ready')
                        utils.setCookie('loader_played', 'true', 7);
                    }, '-=0.8')
        }else{
            loaderAlreadyPlayed = true
            utils.dispachEvent(body, 'load')
            utils.setCookie('loader_played', 'true', 7);
        }

        window.addEventListener('load', function () {
            if (loaderAlreadyPlayed) {
                utils.dispachEvent(body, 'dom:ready')
            } else {
                setTimeout(() => {
                    tlLoader.play()
                    localStorage.setItem('loader_played', 'true');
                }, 1000);
            }
        })

        // Set CSS Variables
        /////////////////////////////////////////////////////////////////////////////////
        utils.setCSSVariables('--viewport-height', `${window.innerHeight}px`)
        window.addEventListener('resize', () => utils.setCSSVariables('--viewport-height', `${window.innerHeight}px`))

        // SplitText
        ////////////////////////////////////////////////////////////////////////////////
        const splitItems = document.querySelectorAll('[data-splittext]');

        splitItems.forEach( item => {
            const type = item.dataset.splittext ? item.dataset.splittext : 'words';
            const split = new SplitText(item, { 
                type: type, 
                linesClass: 'item-line',
                wordsClass: 'item-word', 
                charsClass: 'item-char' 
            });

            item['split'] = split;
        });

        body.addEventListener('dom:ready', () => {
            splitItems.forEach( splitItem => splitItem.classList.add('--ready') ) 
        }, false);
        
        // Parallax item
        /////////////////////////////////////////////////////////////////////////////////
        const parallaxItems = document.querySelectorAll('[data-parallax-item]')

        if (parallaxItems.length) {
            parallaxItems.forEach(item => {

                const parallaxTrigger = item.closest('[data-parallax]')

                gsap.from(item, {
                    yPercent: 100,
                    ease: "none",
                    scrollTrigger: {
                        start: "top bottom-=20%",
                        end: "bottom-=40%",
                        trigger: parallaxTrigger,
                        scrub: true
                    }, 
                  });
                  
            })
        }

        // Gsap Manager
        /////////////////////////////////////////////////////////////////////////////////
        const manager = new GsapManager({            
            loadEvent: [body, 'dom:ready'],
            scrollTrigger: {
                markers: false
            },
            tweenParams: {
                start: '-=0.6'
            }
        });

        // Add some predefined animation
        manager.addItem('fadeInUp', ({timeline, item, start}) => {
            timeline.from(item, { y: 60, opacity: 0, ease: 'power2.out', duration: 1 }, start)
        });

        manager.addItem('zoomOut', ({timeline, item, start}) => {
            timeline.to(item, { scale: 1, ease: 'power2.inOut', duration: 1 }, start)
        });

        manager.addItem('gridItems', ({timeline, item, start}) => {
            const items = item.querySelectorAll('[data-anim-stagger]');
            timeline.from(items, { autoAlpha: 0, y: 40, ease: 'power2.inOut', duration: 1, stagger:0.1 }, start)
        });

        manager.addItem('splittext', ({timeline, item, start}) => {
            const delay = item.dataset.delay ? item.dataset.delay : 0.01;
            timeline.from(item.split.words, { autoAlpha: 0, y: 20, duration: 1, ease: 'power4.inOut', stagger: 0.03 }, start)
        });

        manager.init();

        // Print
        /////////////////////////////////////////////
        const printBtns = document.querySelectorAll('.js-print')

        if (printBtns.length) {
            printBtns.forEach(btn => {
                btn.addEventListener('click', function(e){
                    e.preventDefault()
                    window.print()
                })
            })
        }

    }
}