/**
 * @function setCSSVariables 
 * @param {HTMLDivElement} the element which we want to set css vars
 * @param {String} varsName : the name of css vars
 * @param {String} value : the value of css vars 
 */
export function setCSSVariables(varsName, value, element = false){
    let targetElement = element ? element : document.documentElement;
    targetElement.style.setProperty(varsName, value);
}

/**
 * @function
 * @name outerHeight Get height with margin top and bottom
 */
export function outerHeight(el) {
    let height = el.offsetHeight;
    const style = getComputedStyle(el);
  
    height += parseInt(style.marginTop) + parseInt(style.marginBottom);
    return height;
}

/**
 * @function
 * @name disableScroll
 */
export function disableScroll(){
    document.querySelector('body').classList.add('--scroll-disabled');
}

/**
 * @function
 * @name enableScroll
 */
export function enableScroll(){
    document.querySelector('body').classList.remove('--scroll-disabled');
}

/**
 * @function
 * @name dispachEvent dispatch event on element
 */
export function dispachEvent(element, eventName, datas = {}){
    const event = new CustomEvent(eventName, { detail : datas });

    element.dispatchEvent(event);
}

/**
 * @function
 * @name setCookie 
 */
export function setCookie(name, value, daysToExpire) {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));

    const cookieString = `${name}=${value || ''}; expires=${expirationDate.toUTCString()}; path=/`;
    document.cookie = cookieString;
}

export function getCookie(name) {
    const cookieName = `${name}=`;
    const cookieArray = document.cookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }

    return null;
}