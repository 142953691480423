export default {
	init: (utils, gsap) => {

        const wordTl = gsap.timeline({
            paused: true,
            repeat: -1
        });

        const words = document.querySelectorAll('.js-word')

        words.forEach((word, index) => {
			wordTl.addLabel(`word${index}`, "+=0")
			wordTl.set(word, {display: 'inline'}, `word${index}`)
			wordTl.from(word, { x: 40, autoAlpha: 0, duration: 1, ease: 'power2.out' }, `word${index}`)
			wordTl.to(word, { x: -40, autoAlpha: 0, duration: 1, ease: 'power2.out' }, `word${index}+=2`)
			wordTl.set(word, {display: 'none'}, `+=0`)
        })

		wordTl.play()

        // const textAnimationsOptions = {
        //     slides: document.querySelectorAll('.js_word'),
        //     list: document.querySelector('.js_word_list'),
        //     duration: 0.3,
        //     lineHeight: 50
        // }
          
        
          
        // textAnimationsOptions.slides.forEach(function(slide, i) {

        //     // Create a label
        //     let label = "word" + i;
        //     wordTl.add(label);
            
        //     // Move the whole word
        //     wordTl.set(slide, {display: "inline"}, label)

        //     wordTl.fromTo(slide, {
        //         autoAlpha: 0,
        //         y: 50
        //     },{
        //         duration: 0.5,
        //         y: 0,
        //         autoAlpha: 1
        //     }, label);

        //     wordTl.fromTo(slide, {
        //         autoAlpha: 1
        //     },{
        //         duration: 0.5,
        //         autoAlpha: 0
        //     }, "word" + i + "+=1");

        //     wordTl.set(slide, {display: "none"}, "word" + i + "+=1")

        // })

        // wordTl.play();

	}
}