export default {
	init: (Swiper, Pagination, Autoplay, Navigation) => {

        // Slider testimonials
        ////////////////////////////////////////////////
        const sliderEls = document.querySelectorAll('.js_testimonials_slider')

        if (sliderEls.length) {
            sliderEls.forEach(slider => {

                const prevControls = slider.closest('.js_testimonials_slider').querySelector('.js_testimonials_slider-prev')
                const nextControls = slider.closest('.js_testimonials_slider').querySelector('.js_testimonials_slider-next')
                
                const testimonialsSwiper = new Swiper(slider, {
                    slidesPerView: 1,
                    centerInsufficientSlides: true,
                    modules: [Pagination, Autoplay, Navigation],
                    autoplay: {
                        delay: 8000
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                        clickable: true
                    },
                    breakpoints: {
                        540: {
                          slidesPerView: 2
                        },
                        991: {
                            slidesPerView: 3
                        },
                    },
                    navigation: {
                        nextEl: nextControls,
                        prevEl: prevControls,
                    }
                })

            })
        }

	}
}