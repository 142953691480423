class Router
{	
	 /**
     * Create a Router.
     * @param {Array} routes - An Array containing all application routes
     */
	constructor(routes){
		this.routes = routes;
	}

	/**
	 * @function load
	 * this function load all routes
	 */
	load(){
		const routes = this.routes;

		routes.map((route) => {
			const resolve = route.resolve;
			
			if(typeof route.resolve !== 'undefined'){
				document.querySelectorAll(resolve).length && this.runInitialization(route)
			} else {
				this.runInitialization(route)
			}
		});
	}

	/**
	 * @function runInitialization
	 * This function call the init function of the route file
	 * @param {Object} route : the route informations
	 */
	runInitialization(route){
		route.file.init.apply(null, route.dependencies)
	}
}

export default Router;